"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * A mock readable-stream, using string to read from
 */
const stream_1 = require("stream");
/**
 * Converts a ReadableStream
 * https://developer.mozilla.org/en-US/docs/Web/API/ReadableStreamDefaultReader/read
 */
class Browser2NodeStream extends stream_1.Readable {
    constructor(stream) {
        super();
        this.bytesRead = 0;
        this.reader = stream.getReader();
    }
    _read() {
        this.reader.read().then(res => {
            if (res.done) {
                this.push(null);
            }
            else {
                this.bytesRead += res.value.length;
                this.push(res.value);
            }
        });
    }
    _destroy(error, callback) {
        this.reader.cancel().then(() => {
            callback(null);
        }).catch(err => {
            callback(err);
        });
    }
}
exports.Browser2NodeStream = Browser2NodeStream;
