"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const initDebug = require("debug");
const mm = require("music-metadata/lib/core");
const toBuffer = require("typedarray-to-buffer");
const Browser2NodeStream_1 = require("./fetch/Browser2NodeStream");
const debug = initDebug('music-metadata-browser');
/**
 * Parse audio Stream
 * @param stream
 * @param {string} contentType MIME-Type
 * @param {IOptions} options Parsing options
 * @returns {Promise<IAudioMetadata>}
 */
exports.parseNodeStream = mm.parseStream;
/**
 * Parse Web API ReadableStream: https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream
 * @param {ReadableStream} stream ReadableStream
 * @param {string} contentType MIME-Type
 * @param {IOptions} options Parsing options
 * @returns {Promise<IAudioMetadata>}
 */
function parseReadableStream(stream, contentType, options) {
    const ns = new Browser2NodeStream_1.Browser2NodeStream(stream);
    return exports.parseNodeStream(ns, contentType, options).then(res => {
        debug(`Completed parsing from stream 1bytesRead=${ns.bytesRead} / fileSize=${options && options.fileSize ? options.fileSize : '?'}`);
        return res;
    });
}
exports.parseReadableStream = parseReadableStream;
/**
 * Parse audio from Node Buffer
 * @param {Stream.Readable} stream Audio input stream
 * @param {string} mimeType <string> Content specification MIME-type, e.g.: 'audio/mpeg'
 * @param {IOptions} options Parsing options
 * @returns {Promise<IAudioMetadata>}
 * Ref: https://github.com/Borewit/strtok3/blob/e6938c81ff685074d5eb3064a11c0b03ca934c1d/src/index.ts#L15
 */
exports.parseBuffer = mm.parseBuffer;
/**
 * Parse Web API File
 * @param {Blob} blob
 * @param {IOptions} options Parsing options
 * @returns {Promise<IAudioMetadata>}
 */
function parseBlob(blob, options) {
    return convertBlobToBuffer(blob).then(buf => {
        return mm.parseBuffer(buf, blob.type, options);
    });
}
exports.parseBlob = parseBlob;
/**
 * Parse fetched file, using the Web Fetch API
 * @param {string} audioTrackUrl URL to download the audio track from
 * @param {IOptions} options Parsing options
 * @returns {Promise<IAudioMetadata>}
 */
function fetchFromUrl(audioTrackUrl, options) {
    return fetch(audioTrackUrl).then(response => {
        const contentType = response.headers.get('Content-Type');
        const headers = [];
        response.headers.forEach(header => {
            headers.push(header);
        });
        if (response.ok) {
            if (response.body) {
                return this.parseReadableStream(response.body, contentType, options).then(res => {
                    response.body.cancel();
                    return res;
                });
            }
            else {
                // Fall back on Blob
                return response.blob().then(blob => {
                    return this.parseBlob(blob, options);
                });
            }
        }
        else {
            throw new Error(`HTTP error status=${response.status}: ${response.statusText}`);
        }
    });
}
exports.fetchFromUrl = fetchFromUrl;
/**
 * Parse audio from ITokenizer source
 * @param {strtok3.ITokenizer} Audio source implementing the tokenizer interface
 * @param {string} mimeType <string> Content specification MIME-type, e.g.: 'audio/mpeg'
 * @param {IOptions} options Parsing options
 * @returns {Promise<IAudioMetadata>}
 */
exports.parseFromTokenizer = mm.parseFromTokenizer;
/**
 * Convert Web API File to Node Buffer
 * @param {Blob} blob Web API Blob
 * @returns {Promise<Buffer>}
 */
function convertBlobToBuffer(blob) {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onloadend = event => {
            let data = event.target.result;
            if (data instanceof ArrayBuffer) {
                data = toBuffer(new Uint8Array(event.target.result));
            }
            resolve(data);
        };
        fileReader.onerror = error => {
            reject(new Error(error.type));
        };
        fileReader.onabort = error => {
            reject(new Error(error.type));
        };
        fileReader.readAsArrayBuffer(blob);
    });
}
/**
 * Create a dictionary ordered by their tag id (key)
 * @param {ITag[]} nativeTags list of tags
 * @returns {INativeTagDict} Tags indexed by id
 */
exports.orderTags = mm.orderTags;
/**
 * Convert rating to 1-5 star rating
 * @param {number} rating Normalized rating [0..1] (common.rating[n].rating)
 * @returns {number} Number of stars: 1, 2, 3, 4 or 5 stars
 */
exports.ratingToStars = mm.ratingToStars;
