"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ReadStreamTokenizer_1 = require("./ReadStreamTokenizer");
const BufferTokenizer_1 = require("./BufferTokenizer");
/**
 * Construct ReadStreamTokenizer from given Stream.
 * Will set fileSize, if provided given Stream has set the .path property/
 * @param stream Stream.Readable
 * @param size If known the 'file' size in bytes, maybe required to calculate the duration.
 * @returns ReadStreamTokenizer
 */
function fromStream(stream, size) {
    return new ReadStreamTokenizer_1.ReadStreamTokenizer(stream, size);
}
exports.fromStream = fromStream;
/**
 * Construct ReadStreamTokenizer from given Buffer.
 * @param buffer Buffer to tokenize
 * @returns BufferTokenizer
 */
function fromBuffer(buffer) {
    return new BufferTokenizer_1.BufferTokenizer(buffer);
}
exports.fromBuffer = fromBuffer;
